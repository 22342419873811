<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div class="sidebar-shop" :class="{ show: mqShallShowLeftSidebar }">
        <b-row>
          <b-col cols="12">
            <!-- <h6 class="filter-heading d-none d-lg-block">
              {{ $t("broker.Broker_Filters") }}
            </h6> -->
          </b-col>
        </b-row>

        <!-- Filters' Card -->
        <b-card class="divFilters">
          <b-row>
            <b-col cols="12" class="">
              <h6 class="filter-heading d-none d-lg-block">
                {{ $t("broker.Broker_Filters") }}
              </h6>
              <b-button
                type="reset"
                variant="outline-secondary"
                @click="reset"
                class="mb-2 resetBtn"
              >
                {{ $t("broker.Reset") }}
              </b-button>
            </b-col>
          </b-row>
          <br />
          <div class="price-slider">
            <h6 class="filter-title mt-0">
              {{ $t("broker.Exprerince") }}
            </h6>
            <vue-slider
              v-model="filters.experience"
              :min="1"
              stacked
              :max="40"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div>
          <br />
          <div id="app" class="price-slider">
            <h6 class="filter-title mt-1">
              {{ $t("broker.Target_Industries") }}
            </h6>
            <treeselect
              v-model="filters.industry"
              :multiple="true"
              label="industry ..."
              :options="industries"
              :normalizer="
                $store.state.locale.locale == 'ar'
                  ? normalizerArabic
                  : normalizer
              "
            />
          </div>
          <!-- Location -->
          <div id="app" class="price-slider">
            <h6 class="filter-title mt-1">
              {{ $t("broker.Nationality") }}
            </h6>
            <treeselect
              v-model="filters.nationality"
              :multiple="true"
              label="nationaity ..."
              :options="countries"
            />
          </div>
          <div class="price-slider">
            <h6 class="filter-title mt-1">
              {{ $t("broker.Languages") }}
            </h6>
            <treeselect
              v-model="filters.languages"
              :multiple="true"
              label="nationaity ..."
              :options="calcLanguages"
            />
          </div>
          <div id="app" class="price-slider">
            <h6 class="filter-title mt-1">
              {{ $t("broker.Location") }}
            </h6>
            <treeselect
              v-model="filters.location"
              :multiple="true"
              label="location ..."
              :options="countries"
            />
          </div>
          <!-- Ratings -->
          <div class="ratings">
            <h6 class="filter-title">
              {{ $t("broker.Ratings") }}
            </h6>
            <div
              v-for="rating in filterOptions.ratings"
              :key="rating.rating"
              class="ratings-list"
            >
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[
                      { 'fill-current': star <= rating.rating },
                      star <= rating.rating ? 'text-warning' : 'text-muted',
                    ]"
                    @click="log(rating.rating)"
                  />
                  <span class="ml-25">{{ $t("broker.up") }}</span>
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import millify from "millify";

import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import languages from "@/store/country/nationality.json";

import { BRow, BCol, BButton, BCard, BLink } from "bootstrap-vue";
import VueSlider from "vue-slider-component";

export default {
  components: {
    BRow,
    BButton,
    BCol,
    BLink,
    BCard,
    Treeselect,
    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      languages,
    };
  },
  computed: {
    calcLanguages() {
      return this.languages.map((row) => ({ id: row.text, label: row.text }));
    },
    estInterval() {
      // `this` points to the component instance
      return (this.filters.Established[1] - this.filters.Established[0]) / 1;
    },
    ...mapGetters({
      categories: "profile/getCompanyCategories",
      industries: "profile/getAllIndustries",
      countries: "country/getFilterCountries",
    }),
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCountries();
  },
  methods: {
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    reset() {
      this.filters.experience = [0, 40];
      this.filters.languages = null;
      this.filters.industry = [];
      this.filters.location = [];
      this.filters.nationality = [];
      this.filters.q = "";
      this.filters.ratings = null;
    },
    log(id) {
      this.filters.ratings = id;
    },
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
    }),
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
<style>
@media (max-width: 991.98px) {
  [dir] .ecommerce-application .sidebar-left .sidebar .sidebar-shop.show {
    margin-top: 65px;
  }
}
</style>
<style scoped>
.divFilters {
  background-color: #fff;
  margin-top: 3rem;
  /* padding-top: 20px; */
}
@media (max-width: 600px) {
  .divFilters {
    margin-top: 1rem;
  }
}
div.card.ebitdaBlock {
  border-top: 1px solid #ebe9f1;
}
.resetBtn {
  width: 88%;
}

.divFilters h6 {
  font-weight: 500;
  font-size: 1.1rem;
}
</style>
