import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: null,
    priceRangeDefined: 'all',
    experience: [1, 40],
    MonthlySales: [1000, 1000000000],
    categories: [],
    entityType: [],
    Established: [1960, new Date().getFullYear()],
    industry: null,
    ratings: null,
    location: null,
    nationality: null,
    designation: '',
    languages: null,
    skills: null,
    page: 1,
    perPage: 1,
  })

  const filterOptions = {
    // priceRangeDefined: [
    //   { text: "All", value: "all" },
    //   { text: "<= $10", value: "<=10" },
    //   { text: "$10 - $100", value: "10-100" },
    //   { text: "$100 - $500", value: "100-500" },
    //   { text: ">= $500", value: ">=500" },
    // ],
    categories: [
      'Full Sale',
      'Investment',
      'Loan for business',
      'Selling or Leasing',
    ],
    entityType: [
      { value: '1', text: 'Sole Proprietorship /Sole Trader' },
      { value: '2', text: 'General Partnership' },
      { value: '3', text: 'Limited Liability Partnership (LLP)' },
      { value: '4', text: 'Limited Liability Company (LLC)' },
      { value: '5', text: 'Private Limited Company' },
      { value: '6', text: 'Public Limited Company' },
      { value: '7', text: 'S Corporation' },
      { value: '8', text: 'C Corporation' },
      { value: '9', text: 'Other' },
    ],

    ratings: [
      { rating: 4 },
      { rating: 3 },
      { rating: 2 },
      { rating: 1 },
    ],
  }

  // Sorting
  const sortBy = ref({ text: 'Featured', value: 'featured' })
  const sortByOptions = [
    { text: 'Featured', value: 'featured' },
    { text: 'Lowest', value: 'price-asc' },
    { text: 'Highest', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const fetchProducts = data => store.dispatch('profile/retreiveBrokers', data)

  return {
    products,
    fetchProducts,
  }
}
