import { render, staticRenderFns } from "./BrokerCardFilter2.vue?vue&type=template&id=b24d8ecc&scoped=true&"
import script from "./BrokerCardFilter2.vue?vue&type=script&lang=js&"
export * from "./BrokerCardFilter2.vue?vue&type=script&lang=js&"
import style0 from "./BrokerCardFilter2.vue?vue&type=style&index=0&id=b24d8ecc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b24d8ecc",
  null
  
)

export default component.exports