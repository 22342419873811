<template>
    <div class="company-list-head">
      <div class="company-list-body">
        <Header class="header-companylist" />
  
        <div style="height: inherit">
          <div v-if="!loading">
            <skeleton-list-vue
              :loading="!loading"
              :item-number="6"
              :number-in-row="4"
              :height="'1000'"
            />
          </div>
          <div v-if="loading">
          
          <!-- ECommerce Header -->
          <section id="ecommerce-header">
            <div class="row">
              <div class="col-sm-12">
                <div class="ecommerce-header-items">
                  <div class="result-toggler">
                    <feather-icon
                      icon="MenuIcon"
                      class="d-block d-lg-none"
                      size="21"
                      @click="mqShallShowLeftSidebar = true"
                    />
                    <!-- <div class="search-results">
                      {{ totalProducts }} {{ $t("broker.results_found") }}
                    </div> -->
                    </br>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <!-- Overlay -->
          <div class="body-content-overlay" />
  
          <!-- Searchbar -->
          <div class="ecommerce-searchbar mt-1">
            <b-row>
              <b-col cols="12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="filterParams.q"
                    :placeholder="$t('broker.Search_in_brokers')"
                    class="search-product"
                  />
                  <b-input-group-append is-text>
                    <feather-icon icon="SearchIcon" class="text-muted" />
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </div>
  
          <!-- Prodcuts -->
          <section :class="itemView">
            <b-card
              v-for="(data, index) in brokers"
              :key="index"
              class="ecommerce-card"
              no-body
            >
            <broker-card :broker="data" />
            </b-card>
          </section>
          <!-- Pagination -->
          <section>
            <b-row>
              <b-col cols="12">
                <b-pagination
                  v-model="filterParams.page"
                  :total-rows="brokersCount"
                  :per-page="filterParams.perPage"
                  first-number
                  align="center"
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </section>
        </div>

          <!-- Sidebar -->
          <portal to="content-renderer-sidebar-detached-left" v-if="!loading">
            <leftSideSkeletonVue />
          </portal>

          <portal to="content-renderer-sidebar-detached-left" v-if="loading">
            <shop-left-filter-sidebar
              :filters="filters"
              :filter-options="filterOptions"
              :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
            />
          </portal>
        </div>
      </div>
      <Footer class="footer-companyList" />
    </div>
  </template>
  
  <script>
  import { ref, watch } from "@vue/composition-api";
  import Header from "@/layouts/landpage/Header.vue";
  import Footer from "@/layouts/landpage/Footer.vue";
  import brokerCard from "@/layouts/landpage/cardList/BrokerCardFilter2.vue";

  import SkeletonListVue from "@/views/nftdubai/components/SkeletonList.vue";
  import leftSideSkeletonVue from "@/views/nftdubai/components/leftSideSkeleton.vue";
  import {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BButton,
    BPagination,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import moment from "moment";
  
  // eslint-disable-next-line import/no-cycle
  import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import ShopLeftFilterSidebar from "./ECommerceShopLeftFilterSidebar.vue";
  // eslint-disable-next-line import/no-cycle
  import {
    useShopFiltersSortingAndPagination,
    useShopUi,
    useShopRemoteData,
  } from "./useECommerceShop";
  // eslint-disable-next-line import/no-cycle
  import { useEcommerceUi } from "./useEcommerce";
  
  export default {
    directives: {
      Ripple,
    },
    components: {
      Header,
      Footer,
      brokerCard,
      // BSV
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BCard,
      BCardBody,
      BLink,
      BButton,
      BPagination,
  
      // SFC
      ShopLeftFilterSidebar,

      SkeletonListVue,
      leftSideSkeletonVue
    },
    data() {
      return {
        subname: "",
        rating: 4,
      };
    },
    computed: {
      ...mapGetters({
        brokers: "profile/getAllBrokers",
        brokersCount: "profile/getBrokersCount",
      }),
    },
    created() {
      this.navHidden(true);
      this.footerHidden({ type: "hidden" });
      this.navBarHidden({ type: "hidden" });
    },
    mounted() {
      this.substrData();
    },
    methods: {
      ...mapMutations({
        navHidden: "appConfig/UPDATE_NAV_MENU_HIDDEN",
        footerHidden: "appConfig/UPDATE_NAV_MENU_HIDDEN",
        navBarHidden: "appConfig/UPDATE_NAVBAR_CONFIG",
      }),
      ...mapActions({
        fetchFreelancers: "profile/retreiveBrokers",
      }),
      formatDate(value) {
        return moment(String(value)).format("LL");
      },
      substrData() {
        // this.subname = name.substring(1, 4);
        // produc-name
        const elements = document.getElementsByClassName("produc-name");
        console.log(elements.innerText);
  
        // alert(text1);
      },
    },
    setup() {
      const loading = ref(false);

      const filterParams = ref({
        page: 1,
        perPage: 9,
        q: "",
      });
      const { filters, filterOptions, sortBy, sortByOptions } =
        useShopFiltersSortingAndPagination();
  
      const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();
  
      const { itemView, itemViewOptions, totalProducts } = useShopUi();
  
      const { products, fetchProducts } = useShopRemoteData();
  
      const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
  
      // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
      const fetchShopProducts = () => {
        const formData = new FormData();
        formData.append("page", filterParams.value.page);
        if (filterParams.value.q) {
          formData.append("q", filterParams.value.q);
        }
        if (filters.value.languages && filters.value.languages.length > 0) {
          formData.append("languages", JSON.stringify(filters.value.languages));
        }
        if (filters.value.nationality && filters.value.nationality.length > 0) {
          formData.append(
            "nationality",
            JSON.stringify(filters.value.nationality)
          );
        }
        if (filters.value.industry && filters.value.industry.length > 0) {
          formData.append("industries", JSON.stringify(filters.value.industry));
        }
        if (filters.value.location && filters.value.location.length > 0) {
          formData.append("locations", JSON.stringify(filters.value.location));
        }
        if (filters.value.ratings) {
          formData.append("rating", JSON.stringify([filters.value.ratings, 5]));
        }
        formData.append("experiences", JSON.stringify(filters.value.experience));
        fetchProducts(formData)
          .then(() => { loading.value = true; 
            window.scrollTo(0, 0);
          })
          .catch(() => {});
      };
      fetchShopProducts();
  
      watch(
        [filters, sortBy, filterParams],
        () => {
          fetchShopProducts();
        },
        {
          deep: true,
        }
      );
  
      return {
        // useShopFiltersSortingAndPagination
        filters,
        filterOptions,
        sortBy,
        sortByOptions,
  
        // useShopUi
        itemView,
        itemViewOptions,
        totalProducts,
        toggleProductInWishlist,
        handleCartActionClick,
  
        // useShopRemoteData
        products,
        filterParams,
  
        // mqShallShowLeftSidebar
        mqShallShowLeftSidebar,
        loading,
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "~@core/scss/base/pages/app-ecommerce.scss";
  </style>
  
  <style lang="scss" scoped>
  .item-view-radio-group ::v-deep {
    .btn {
      display: flex;
      align-items: center;
    }
  }
  </style>
  <style scoped>
  @media (min-width: 992px) {
    .footer-companyList {
      margin-left: -316px;
      margin-right: -30px;
      margin-bottom: -22px;
  
    }
  }
  
  .item-date-located {
    display: flex;
    justify-content: space-between;
  }
  .item-located-text {
    color: #000;
  }
  .rate-sales {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    margin-bottom: 2px;
  }
  .item-EBITDA {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 2px;
  }
  .item-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .item-social .fa-brands {
    margin-right: 10px;
  }
  .item-wrapper {
    margin-bottom: 5px;
  }
  .item-desc-img {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 120px;
  }
  .item-desc-img p {
    font-size: 13px;
  }
  .fa-location-dot {
    color: #516ccf;
  }
  
  .rate-sales .rate-sales-text,
  .item-EBITDA .item-EBITDA-text {
    color: #0b2c8d;
    font-weight: 500;
  }
  .rate-sales .rate-sales-value,
  .item-EBITDA .item-EBITDA-value {
    color: #000;
    font-weight: 500;
  }
  .rate-sales p,
  .item-EBITDA p {
    padding: 5px 5px 0 5px;
  }
  .sale-type {
    margin-left: 40px;
  }
  .sale-type .sale-type-text {
    font-weight: 500;
    text-align: center;
    color: #6e6b7b;
  }
  .sale-type .sale-type-value {
    color: #000;
    font-weight: 500;
    text-align: center;
    margin-top: -10px;
  }
  .item-wrapper {
    margin-top: 10px;
  }
  
  .product-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6e6b7b;
  }
  .industry-value {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6e6b7b;
  }
  .item-legalEntity {
    color: #6e6b7b;
  }
  .company-list-body {
    min-height: 900px;
  }
  </style>
  <style scoped>
  .breadcrumbs-top {
    margin-top: 65px;
  }
  </style>
  <style scoped>
  .heading-topComany {
    font-size: 32px;
    line-height: 48px;
    margin: 32px 0;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid #dcdcdc;
    width: 100%;
    padding-bottom: 15px;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .topCompany .view-all a {
    font-size: 16px;
    font-weight: 600;
  }
  
  .itemName {
    padding: 1rem 1rem 0 1rem;
  }
  [dir] .card-body {
    padding: 0.5rem 1rem 0 1rem;
  }
  
  
  
  .item-date-located {
    display: flex;
    justify-content: space-between;
  }
  
  .item-wrapper {
    margin-bottom: 5px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .fa-location-dot {
    color: #516ccf;
  }
  
  .item-legalEntity {
    color: #6e6b7b;
  }
  .company-list-body {
    min-height: 900px;
  }
  
  .card-header-freelancer {
    display: flex;
    justify-content: space-between;
    /* flex-direction: row; */
    flex-wrap: wrap;
    align-content: stretch;
    align-items: cente;
  }
  .card-header-freelancer .card-top-img img {
    width: 75px;
    height: 75px;
    border-radius: 40px;
    margin-right: 15px;
  }
  .freelancer-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4b4b4b;
    font-weight: 600;
  }
  .item-desc-img {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    min-height: 50px;
  }
  
  .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    font-weight: 500;
    font-size: 13px;
    color: #062455;
  }
  .skills-value {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6e6b7b;
    font-size: 12px;
  }
  .industry-value {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #6e6b7b;
    font-size: 12px;
  }
  .item-footer {
    margin-bottom: 15px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .sad {
    color: #1476b9;
    font-weight: 600;
  }
  @media (max-width: 700px) {
    .heading-topComany {
      font-size: 20px;
    }
  }
  .text-black {
    color: #000;
    font-weight: 500;
  }
  .text-darkblue {
    color: #062455;
    font-weight: 500;
  }
  .company-list-head {
    margin-top: 22px;
  }
  @media (max-width: 600px) {
  [dir] .company-list-head {
    margin-top: 55px;
  }
}
  </style>
  
<style>
[dir] .content-wrapper {
  padding-bottom: 0px;
  margin-top: 15px;
}
[dir] .ecommerce-application .ecommerce-card:hover {
  transform: translateY(0px);
  box-shadow: 0 0px 0px 0 rgb(34 41 47 / 8%);
}
[dir] .ecommerce-application .ecommerce-searchbar .input-group {
  border: 1px solid rgb(238 238 238);
  box-shadow: 2px 1px 2px rgb(0 0 0 / 6%);
}
footer.footer.footer-light.footer-static {
  display: none;
}
</style>